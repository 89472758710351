import React from 'react';

const RedlineContact = () => {
  return (
    <div className="row">
      <div className="col-md-2">&nbsp;</div>
      <div className="col-md-8">
      <h1>Support</h1>
      <p>
      For support please contact: <a href="mailto:ron@orangemantis.net?subject=Redline Kings Support">ron@orangemantis.net</a>
      </p>
      </div>
      <div className="col-md-2">&nbsp;</div>
    </div>
  );
}

export default RedlineContact;
