import React from 'react';
import iosBadge from './images/iosappstore.png';

const RedlineHome = () => {
  return (
    <div className="row">
      <div className="col-md">
        <div className="row">
          <div className="col-md-2">
          &nbsp;
          </div>
          <div className="col-md-8">
            <h1>Download History</h1>
            <p className="desc">
              Redline Kings tells the formative story of Cleveland Graffiti.
              It contains never before published photos of artwork that has long since faded.
            </p>
          </div>
          <div className="col-md-2">
          &nbsp;
          </div>
        </div>
        <div className="row">
          <div className="col-md text-center badge-box">
            <a href="https://apps.apple.com/us/app/redline-kings/id1471171207">
            <img className="ios-badge" src={iosBadge} alt="iOS App Store Badge"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedlineHome;
