import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import RedlineHome from './RedlineHome' ;
import RedlinePrivacy from './RedlinePrivacy';
import RedlineContact from './RedlineContact';

const Routes = () => (
    <main>
      <Switch>
        <Route exact path="/redlinekings/" component={RedlineHome}/>
          <Route exact path="/" component={RedlineHome}/>
        <Route exact path="/products/redlinekings/" component={RedlineHome}/>
        <Route path="/products/redlinekings/privacy" component={RedlinePrivacy}/>
        <Route path="/products/redlinekings/contact" component={RedlineContact}/>
      </Switch>
    </main>
);

export default Routes;
