import React from 'react';
import Routes from './Routes'
import './App.css';
import logo from './images/redline-logo.png';
import omcLogo from './images/orange-mantis-logo@1x.png';

function App() {
  return (
      <>
        <div className="app container">
            <header className="app-header text-center">
                <img className="logo" src={logo} alt="Redline Kings logo"/>
            </header>
            <main className="byline text-center">
                <Routes/>
            </main>
        </div>
        <footer className="page-footer text-center">
            <img className="footer-logo" src={omcLogo} alt="orange mantis Creative LLC logo" />
        </footer>
      </>
  )
}

export default App;
